/* Remove container */
.MuiContainer-root {
  max-width: unset !important;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: var(--scroll-bcg);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 4px solid transparent;
  background-clip: padding-box;
  background-color: var(--scroll-color);
}

.number_text_field input[type=number] {
    -moz-appearance: textfield;
}

.number_text_field input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number_text_field input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}